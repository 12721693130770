<template>
  <div class="container">
    <div>
      <a-row :gutter="20">
        <a-col :span="6" class=" mb-20">
          <a-card class="container-card" hoverable :headStyle="headStyle">
            <div class="container-content">
              <div class="title mb-10">
                <div>企业</div>
                <a-tooltip slot="extra" placement="topRight" arrow-point-at-center>
                  <template slot="title">
                    <span>企业数量</span>
                  </template>
                  <a-icon type="info-circle" />
                </a-tooltip>
              </div>
              <div class="text-num">{{ homeData.enterpriseCount }}</div>
            </div>
          </a-card>
        </a-col>
        <a-col :span="6" class=" mb-20">
          <a-card class="container-card" hoverable :headStyle="headStyle">
            <div class="container-content">
              <div class="title mb-10">
                <div>应用</div>
                <a-tooltip slot="extra" placement="topRight" arrow-point-at-center>
                  <template slot="title">
                    <span>应用数量</span>
                  </template>
                  <a-icon type="info-circle" />
                </a-tooltip>
              </div>
              <div class="text-num">{{ homeData.applicationCount }}</div>
            </div>
          </a-card>
        </a-col>
        <a-col :span="6" class="mb-20">
          <a-card class="container-card" hoverable :headStyle="headStyle">
            <div class="container-content">
              <div class="title mb-10">
                <div>用户</div>
                <a-tooltip slot="extra" placement="topRight" arrow-point-at-center>
                  <template slot="title">
                    <span>用户数量</span>
                  </template>
                  <a-icon type="info-circle" />
                </a-tooltip>
              </div>
              <div class="text-num">{{ homeData.userCount }}</div>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>

  </div>
</template>

<script>
import {
  enterpriseStatistics
} from '@/api/home'
export default {
  name: 'Home',
  data() {
    return {
      headStyle: {
        textAlign: 'left'
      },
      homeData: {
        enterpriseCount: 0,
        userCount: 0,
        applicationCount: 0
      }
    }
  },
  created() {
    this.getEnterpriseStatistics()
  },
  methods: {
    getEnterpriseStatistics() {
      enterpriseStatistics().then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        this.homeData = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  background-color: #f0f2f5;
  .container-card{
    height: 180px;
    margin: 0px auto;
    ::v-deep .ant-card-body{
      height: calc(100% - 30px);
      .container-content{
        height: 100%;
        .title{
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .text-num{
          height: 100%;
          font-size: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  ::v-deep .ant-row{
    width: 100%;
  }
}
::v-deep .ant-card{
  .ant-card-body{
    padding: 20px 24px;
  }
}
</style>

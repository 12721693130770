import axios from '@/utils/request'

/**
 * 企业统计
 * @data {*} data
 */
export function enterpriseStatistics(data) {
  return axios({
    url: '/enterprise/statistics',
    method: 'post',
    data
  })
}
